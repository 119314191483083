import React, { useState, useEffect } from "react";
import axios from "axios";

function LeagueTable() {
  let [leaguetableData, setLeagueTableData] = useState({
    competition: "",
    stages: [],
  });

  useEffect(() => {
    async function fetchData() {
      const result = await axios(
        "https://api.onenil.eu/api/league-table"
      ).catch((err) => {
        this.setState({
          err,
          isLoading: false,
        });
      });

      setLeagueTableData({
        competition: result.data.competition,
        stages: result.data.stage,
      });
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>{leaguetableData.competition.name} League Table</h1>
      {leaguetableData.stages.map((stage) => (
        <div key={stage.name}>
          <h2>{stage.name}</h2>

          {stage.division.map((division) => (
            <div key={stage.name + "_" + division.type}>
              <h2>{division.type}</h2>
              {division.ranking.map((ranking) =>
                renderDivision(stage, division, ranking)
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

function renderDivision(stage, division, ranking) {
  if (division.type === "attendance")
    return (
      <div
        key={
          stage.name +
          "_" +
          division.type +
          "_" +
          ranking.contestantId +
          "_" +
          ranking.venueId
        }
      >
        {ranking.rank}
        {ranking.contestantShortName}
        {ranking.venueName}
        {ranking.minimumAttendance}
        {ranking.maximumAttendance}
        {ranking.averageAttendance}
        {ranking.capacity}
        {ranking.percentSold}
      </div>
    );
  return (
    <div key={stage.name + "_" + division.type + "_" + ranking.contestantId}>
      {ranking.rank}
      {ranking.contestantShortName}
      {ranking.matchesPlayed}
      {ranking.goalsFor}
      {ranking.goalsAgainst}
      {ranking.lastSix}
    </div>
  );
}
export default LeagueTable;
