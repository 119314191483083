import React from "react"
import '../styles/layout.css'
import { Outlet } from "react-router-dom"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import Navbar from "../nav-bar";
class Layout extends React.Component {
  render() {
    return (
      <>
        <Header />
        <Navbar />
        <Outlet />
        <main>{this.props.children}</main>
        <Footer />
      </>
    )
  }
}
export default Layout;