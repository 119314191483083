import React, { useState, useEffect } from "react";

function Fixtures() {
  const [optaResponse, setOptaResponse] = useState(0);

  var connection = new WebSocket("wss://content.performgroup.io");

  var outletObject = {
    OutletKeyService: {
      // replace below {outletid} value with your unique ID provided to you by our Client/Account Services
      outletid: "1ihmwp4ip8cn61q0y90965gwlm",
    },
  };

  connection.onopen = function (event) {
    // when connection is opened, send outlet data to get authorized
    connection.send(
      JSON.stringify({
        outlet: outletObject,
      })
    );
  };

  useEffect(() => {
    connection.onmessage = function (event) {
      console.log(event);
      setOptaResponse(event.data);
    };
  }, []);

  return (
    <div>
      <h1>This is the Fixtures Page</h1>
      <p>Repsonse from opta is:</p>
      <div>{optaResponse}</div>
    </div>
  );
}

export default Fixtures;
