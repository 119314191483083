import React from "react";
class Footer extends React.Component {
  render() {
    return(
      <footer>
        <h1>
        Footer goes here
        </h1>
         </footer>
    );
  }
}
export default Footer;