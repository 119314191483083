import React from "react";
class Header extends React.Component {
  render() {
    return(
      <header>
        Header
      </header>
    );
  }
}
export default Header;